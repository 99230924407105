// TODO: remove after upgrading TS at least to 5.1.6

interface CompressionStream extends GenericTransformStream {}
interface DecompressionStream extends GenericTransformStream {}
type CompressionFormat = 'deflate' | 'deflate-raw' | 'gzip';
declare var CompressionStream: {
  prototype: CompressionStream;
  new (format: CompressionFormat): CompressionStream;
};

declare var DecompressionStream: {
  prototype: DecompressionStream;
  new (format: CompressionFormat): DecompressionStream;
};

export default class StringUtils {
  static camelize(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }

  static capitalize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return word.toUpperCase();
    });
  }

  static wordsFromCamelCase(str) {
    if (str === undefined) {
      return undefined;
    }
    return (
      str
        // Insert a space before all uppercase letters that follow a lowercase letter
        .replace(/([a-z])([A-Z])/g, '$1 $2')

        // Uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  }
}
